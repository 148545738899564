import * as _os2 from "os";

var _os = "default" in _os2 ? _os2.default : _os2;

import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import * as _url2 from "url";

var _url = "default" in _url2 ? _url2.default : _url2;

import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import _svgo from "./svgo.js";
import _process from "process";
var exports = {};

function _nullRequire(id) {
  var e = new Error("Cannot find module '" + id + "'");
  e.code = "MODULE_NOT_FOUND";
  throw e;
}

var process = _process;
const os = _os;
const fs = _fs;
const {
  pathToFileURL
} = _url;
const path = _path;
const {
  optimize: optimizeAgnostic
} = _svgo;

const importConfig = async configFile => {
  let config; // at the moment dynamic import may randomly fail with segfault
  // to workaround this for some users .cjs extension is loaded
  // exclusively with require

  if (configFile.endsWith(".cjs")) {
    config = _nullRequire(configFile);
  } else {
    // dynamic import expects file url instead of path and may fail
    // when windows path is provided
    const {
      default: imported
    } = await import(pathToFileURL(configFile));
    config = imported;
  }

  if (config == null || typeof config !== "object" || Array.isArray(config)) {
    throw Error(`Invalid config file "${configFile}"`);
  }

  return config;
};

const isFile = async file => {
  try {
    const stats = await fs.promises.stat(file);
    return stats.isFile();
  } catch {
    return false;
  }
};

const loadConfig = async (configFile, cwd = process.cwd()) => {
  if (configFile != null) {
    if (path.isAbsolute(configFile)) {
      return await importConfig(configFile);
    } else {
      return await importConfig(path.join(cwd, configFile));
    }
  }

  let dir = cwd; // eslint-disable-next-line no-constant-condition

  while (true) {
    const js = path.join(dir, "svgo.config.js");

    if (await isFile(js)) {
      return await importConfig(js);
    }

    const mjs = path.join(dir, "svgo.config.mjs");

    if (await isFile(mjs)) {
      return await importConfig(mjs);
    }

    const cjs = path.join(dir, "svgo.config.cjs");

    if (await isFile(cjs)) {
      return await importConfig(cjs);
    }

    const parent = path.dirname(dir);

    if (dir === parent) {
      return null;
    }

    dir = parent;
  }
};

exports.loadConfig = loadConfig;

const optimize = (input, config) => {
  if (config == null) {
    config = {};
  }

  if (typeof config !== "object") {
    throw Error("Config should be an object");
  }

  return optimizeAgnostic(input, { ...config,
    js2svg: {
      // platform specific default for end of line
      eol: os.EOL === "\r\n" ? "crlf" : "lf",
      ...config.js2svg
    }
  });
};

exports.optimize = optimize;
export default exports;
const _loadConfig = exports.loadConfig,
      _optimize = exports.optimize;
export { _loadConfig as loadConfig, _optimize as optimize };